import React from 'react';
import { graphql } from 'gatsby';
import { injectIntl, Link, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroTitle from '../components/heroTitle';
import Verbatim from '../components/blockContent/verbatim/verbatim';
import FullwidthPlaceholderVideo from '../components/blockContent/fullwidthPlaceholderVideo';
import TextWithTitle from '../components/textWithTitle';
import PeopleList from '../components/blockContent/people/peopleList';
import StatsList from '../components/blockContent/stats/statsList';
import BackToTop from '../components/navigation/backToTop';

const pageAboutUs = ({ pageContext, data, intl }) => {
  const page = data.allContentfulPageAboutUs.nodes[0];

  return (
    <>
      <Layout
        pageContext={pageContext}
        headerCssClass="bkg-color3"
        mainCssClass="page-informations"
        activeMenuLink="aboutus"
      >
        <SEO
          title={page.seo?.metaTitle}
          titleOnglet={page.seo?.title}
          description={page.seo?.metaDescription}
          imageLink={page.seo?.socialImage?.file?.url}
          pageLink={page.seo?.slug}
        />

        {/* HeroTitle */}
        <HeroTitle subtitle={page.surTitre} title={page.titre} />

        <div className="is-relative project-sheet">
          {/* START CUSTOM BLOCKS */}
          {page.customBlocks &&
            page.customBlocks?.map((block, i) => {
              switch (block.__typename) {
                case 'ContentfulBlocCitation':
                  return <Verbatim verbatim={block} key={i} />;

                case 'ContentfulBlocPersonnes':
                  return (
                    <PeopleList
                      peopleList={block.people}
                      title={block.title}
                      key={i}
                    />
                  );

                case 'ContentfulFullWidthMedia':
                  return (
                    <FullwidthPlaceholderVideo
                      title={block.title}
                      visuel={block.visuel}
                      videoLink={block.videoLink}
                      key={i}
                    />
                  );

                case 'ContentfulTexteAvecUnTitre':
                  return (
                    <TextWithTitle
                      key={i}
                      title={block.title}
                      text={
                        block.childContentfulTexteAvecUnTitreTextTextNode
                          .childMarkdownRemark.html
                      }
                      fullWidth
                    />
                  );

                case 'ContentfulBlocsStats':
                  return <StatsList statsList={block.stats} key={i} />;

                default:
                  break;
              }
            })}
          {/* END CUSTOM BLOCKS */}

          {/* BackToTop */}
          <BackToTop />
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query contentfulPageAboutUs($language: String!) {
    allContentfulPageAboutUs(
      filter: { node_locale: { eq: $language } }
      limit: 1
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        titre
        contentful_id
        surTitre
        seo {
          metaTitle
          metaDescription
          socialImage {
            file {
              url
            }
          }
          title
          slug
        }
        customBlocks {
          __typename
          ... on Node {
            ... on ContentfulBlocCitation {
              id
              verbatim {
                childMarkdownRemark {
                  html
                }
              }
              author
              status
            }
            ... on ContentfulBlocPersonnes {
              id
              title
              people {
                id
                name
                linkedin
                job {
                  label
                }
                picture {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  description
                }
              }
            }
            ... on ContentfulFullWidthMedia {
              id
              title
              videoLink
              visuel {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                description
              }
            }
            ... on ContentfulTexteAvecUnTitre {
              id
              title
              childContentfulTexteAvecUnTitreTextTextNode {
                childMarkdownRemark {
                  html
                }
              }
            }
            ... on ContentfulBlocsStats {
              id
              stats {
                chiffres
                information
                units
              }
            }
          }
        }
      }
    }
  }
`;

export default injectIntl(pageAboutUs);
