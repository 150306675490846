import React from 'react';

function stats({ stats }) {
  const numberLength = stats.chiffres.toString().length;
  const cssClass = 'number-' + numberLength;

  return (
    <div className="column is-one-third-widescreen is-half col-stats">
      <span className={`stats-data ${cssClass}`}>
        <span className="title is-1 stats-number">{stats.chiffres}</span>
        <span className="subtitle is-4 has-text-white is-uppercase stats-units">
          {stats.units}
        </span>
      </span>
      <p className="has-text-white">{stats.information}</p>
    </div>
  );
}

export default stats;
