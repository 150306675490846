import React from 'react';
import Stats from './stats';

function StatsList({ statsList }) {
  return (
    <>
      <section className="block-content is-fullwidth block-stats bkg-color2">
        <div className="columns row-stats">
          {statsList?.map((stats, i) => (
            <Stats key={i} stats={stats} />
          ))}
        </div>
      </section>
    </>
  );
}

export default StatsList;
